import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-r4-content',
  templateUrl: './r4-content.component.html',
  styleUrls: ['./r4-content.component.scss']
})
export class R4ContentComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
